.password_divider {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 10px;
    color: var(--secondary-text);
    font-size: 13px;
    padding: 5px 0;
}

.password_divider hr {
    width: 100%;
}

.customOverlayClass {
    min-width: 350px;
    max-width: 400px;
}

@media(max-width: 768px) {
    .customOverlayClass {
        min-width: auto;
    }

}




.option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    padding-top: 3px;
}

.option p {
    cursor: pointer;
}

.password_title {
    text-align: center;
    font-size: 19px;
    font-weight: 400;
    padding: 15px 0;
    color: var(--primary);
}

.customInputClass {
    padding: 3px 0 0px 0;
}

.customInputClass input {
    border-width: 1px;
    border-color: var(--primary);
}

.customInputPasswordClass {
    padding: 8px 0 5px 0;
}


.customInputPasswordClass input {
    border-width: 1px;
    border-color: var(--primary);
}

.customInputSignUpClass {
    padding: 20px 0 5px 0;
}

.customInputSignUpClass input {
    border-width: 1px;
    border-color: var(--primary);
}

.button {
    width: 100%;
    padding: 3px 5px;
    margin: 0;
    margin-bottom: 10px;
    text-align: center;
}

.password_with_google span {
    background: conic-gradient(from -45deg,
            #ea4335 110deg,
            #4285f4 90deg 180deg,
            #34a853 180deg 270deg,
            #fbbc05 270deg) 73% 55%/150% 150% no-repeat;
    width: 25px;
    height: 25px;
    transform: scale(1);
    display: block;
    position: relative;
    clip-path: path('M12.545,10.239v3.821h5.445c-0.712,2.315-2.647,3.972-5.445,3.972c-3.332,0-6.033-2.701-6.033-6.032s2.701-6.032,6.033-6.032c1.498,0,2.866,0.549,3.921,1.453l2.814-2.814C17.503,2.988,15.139,2,12.545,2C7.021,2,2.543,6.477,2.543,12s4.478,10,10.002,10c8.396,0,10.249-7.85,9.426-11.748L12.545,10.239z');
}

.password_with_google {
    display: flex;
    align-items: center;
    column-gap: 5px;
    padding: 10px 0;
    justify-content: center;
    cursor: pointer;
}

.forget_pass_form {
    padding: 0px 0 20px 0;
}

.already_account {
    text-align: center;
    cursor: pointer;
    padding: 10px 0;
}

.status {
    display: flex;
    justify-content: center;
}

.error_status {
    text-align: center;
    color: var(--red);
    font-weight: 300;
    font-size: 14px;
    padding: 5px 20px;
    margin: 5px 0;
}

.success_status {
    text-align: center;
    color: var(--green);
    font-weight: 400;
    padding: 5px 20px;
    margin: 5px 0;
    border-radius: 10px;
    border: 1px solid var(--green);
}

.success_icon {
    margin: 0 auto;
    display: block;
    text-align: center;
}

.success_icon svg {
    font-size: 200px;
    color: var(--primary);
    padding: 10px 0;
}

.message {
    padding: 0 20px;
    max-width: 500px;
}

@media(max-width: 768px) {
    .message {
        padding: 0 10px;
    }
}

.loader svg {
    stroke: var(--count-loader-stroke);
    width: 20px;
    height: 20px;
}