.search_select {
  width: 100%;
  position: relative;
  font-size: 14px;
  cursor: pointer;
}

.search_area {
  display: block;
  width: 100%;
  position: relative;
  font-size: 14px;
  cursor: pointer;
  color: var(--input-color);
}

.search_select label {
  color: var(--primary-bg-text);
  font-size: 15px;
  margin-bottom: 5px;
  margin-top: 1px;
}

.search_select label svg {
  margin-right: 5px;
  font-size: 11px;
  position: relative;
  top: -1px;
}

.search_select label sup {
  top: -0.4em;
  right: 0.2em;
  color: var(--red);
}

.search_btn {
  width: 100%;
  border: 1px solid var(--input-border-color) !important;
  padding: 5px 10px 5px 10px;
  border-radius: 5px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.error {
  width: 100%;
  border: 1px solid var(--red) !important;
  padding: 4px 10px;
  border-radius: 5px;
  position: relative;
}

.search_btn::after {
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' strokeWidth='1.5' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  float: right;
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  position: relative;
}

.search_select ul {
  position: absolute;
  margin-top: 8px;
  margin-bottom: 0;
  background-color: var(--secondary);
  border: 1px solid var(--primary-border-color);
  border-radius: 5px;
  padding: 0;
  list-style: none;
  z-index: 10;
  width: 100%;
  max-height: 200px;
  overflow: auto;
}

.search_select li {
  padding: 5px 10px;
}

.search_select li svg {
  margin-right: 5px;
  font-size: 14px;
}

.search_select li.selected {
  background-color: var(--primary);
  color: #fff;
  cursor: pointer;
}

.search_select li:hover {
  background-color: var(--primary);
  color: #fff;
  cursor: pointer;
}

.select_search {
  padding: 7px 10px 5px 10px;
}

.select_search input {
  width: 100%;
  border: none;
  border-bottom: 1px solid var(--primary-border-color);
  background-color: transparent;
  color: var(--input-color);
  height: 30px;
  font-weight: 300;
  font-size: 13px;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
}

.noData {
  font-size: 14px;
  text-align: center;
  padding: 10px;
  color: grey;
}

.loader svg {
  width: 20px;
  height: 20px;
}

.disabled .search_btn::after {
  content: none !important;
}
