header {
    width: 100%;
    position: sticky;
    top: 0;
    border-bottom: 1px solid var(--header-border-color);
    z-index: 5;
    background: var(--header-bg);
}


.navBar {
    display: flex;
    list-style: none;
    justify-content: center;
    text-decoration: none;
    padding: 10px;
    height: 100%;
    margin-bottom: 0;
    align-items: center;
}

.head_space {
    padding-top: 57px;
}

.header_row {
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: space-between;
    padding: 0 12px;
    width: 100%;
    position: relative;
    height: 60px;
    z-index: 10;
    background-color: var(--header-bg);
    top: 0;
}

/* .header_row::after {
    height: 4px;
    position: absolute;
    top: 100%;
    right: 0px;
    left: 0px;
    background: linear-gradient(rgba(9, 30, 66, 0.13) 0px, rgba(9, 30, 66, 0.13) 1px, rgba(9, 30, 66, 0.08) 1px, rgba(9, 30, 66, 0) 4px);
    content: "";
} */

.header_col_2 {
    display: flex;
    list-style: none;
    align-items: center;
    margin: 0 5px;
}

.header_col_2 li {
    position: relative;
    margin: 0 7px;
}

.header_col_2 li:last-child {
    margin-left: 10px;
}

.header_col_2 svg {
    font-size: 20px;
    color: var(--primary-bg-text);
}

.divider_border_right {
    padding-right: 15px;
    border-right: 1px solid var(--header-border-color);
}

.profile_pic {
    color: var(--primary-bg-text);
    font-size: 13px;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 5px;
    user-select: none;
}

.profile_pic svg {
    font-size: 23px;
}

.profile_name {
    max-width: 135px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: var(--primary-bg-text);
    font-size: 13px;
    font-weight: 300;
    column-gap: 5px;
    user-select: none;
}

@media(max-width: 450px) {
    .profile_name {
        max-width: 60px;
    }

    .header_row {
        padding-left: 0;
    }
}

/* .profile_pic {
    width: 25px;
    height: 25px;
    border-radius: 100%;
    background-color: var(--primary);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 14px;
} */


.logo {
    font-size: 18px;
    font-weight: 400;
    color: var(--primary-bg-text);
    display: flex;
    align-items: center;
    column-gap: 12px;
}

.logo span {
    font-size: 14px;
    font-weight: 400;
    border-radius: 10px;
    border: 1px solid var(--primary);
    padding: 3px 10px;
    background-color: var(--primary);
    color: var(--primary-text);
}

@media(max-width: 768px) {

    .header_col_1 {
        display: none;
    }

    .header_row {
        display: grid;
        justify-content: flex-end;
        align-items: center;
    }
}

.total_count {
    position: absolute;
    top: 2px;
    right: 1.5px;
    min-width: 16px;
    height: 16px;
    border-radius: 100%;
    background-color: var(--red);
    padding: 0 3px;
    border: 2px solid var(--secondary-bg);
}

.total_count::after {
    content: attr(data-count);
    display: flex;
    color: #fff;
    width: auto;
    justify-content: center;
    position: relative;
    align-items: center;
    height: 100%;
    font-size: 9px;
    font-weight: 400;
}

.loader_notification {
    position: absolute;
    bottom: -12px;
    right: 2px;

}

.loader_notification svg {
    width: 10px;
    stroke: #fff !important;
}


/* Notification Box */

.notification_box_section {
    position: absolute;
    min-width: 260px;
    min-height: 185px;
    display: flex;
    background: var(--teritiary);
    z-index: 100;
    border-radius: 5px;
    right: -110px;
    box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.3);
}

.hide_notification {
    display: none;
}

.logo_text_section {
    padding: 5px 0;
}

.logo_text_section h1 {
    font-size: 17px;
    font-weight: 400;
    margin: 0;
    margin-left: 5px;
    color: var(--primary-bg-text);

}


.logo_text_section p {
    text-align: right;
    margin: 0;
    font-size: 13px;
    font-weight: 400;
    line-height: 1;
}

.logo_text_section p span {
    color: #fa9900;
    border: none;
    padding: 0;
    font-size: 12px;
}

.divider {
    height: 41px;
    width: 1px;
    background-color: var(--header-border-color);
}

.search_box_section {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 3px;
}

.search_box_section svg {
    font-size: 23px;
}

.custom_input {
    border: none !important;
    display: flex;
}


.company_name {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 120px;
    white-space: nowrap;
}