.notification_box {
    width: 100%;
    position: relative;
    height: 100%;
    min-height: 185px;
    padding: 0;
    color: var(--primary-bg-text);
}

.notification_head {
    padding-bottom: 5px;
    margin-bottom: 10px;
    border-bottom: 1px solid var(--primary-border-color);
    font-size: 14px;
}

.notification_body {
    height: 100%;
    display: block;
    min-height: 185px;
    max-height: 185px;
    overflow: auto;
    padding: 3px 3px;
}

.no_unread {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 175px;
    font-size: 13px;
    font-weight: 400;
    color: grey;
}

.read_btn {
    width: 100%;
    border-radius: 0;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    padding: 5px 0;
    margin: 0 !important;
}

.loader svg {
    stroke: var(--count-loader-stroke);
}

.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 178px;
}

.notification_table_data {
    border-bottom: 1px solid var(--primary-border-color);
    padding: 7px 10px;
    cursor: pointer;
}

.notification_table_data:last-child {
    border-bottom: 0;
}

.date {
    font-size: 13px;
    color: grey;
}

.content {
    line-height: 1.1;
    font-size: 13px;
    padding: 3px 0px;
    font-weight: 300;
}

.notification_table_data:hover {
    background-color: var(--primary);
    color: var(--primary-text);
}

.notification_table_data:hover:first-child {
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
}

.notification_table_data:hover .date {
    color: #e4e4e4;
}

.load_btn {
    text-align: center;
    margin-top: 6px;
}

.btn_loader svg {
    width: 25px;
    stroke: #fff;
}