.overlayBoxCustom {
    min-width: 80%;
}

.customBodyClass {
    padding: 0;
    height: 100%;
    min-height: 500px;
}

.setting_section {
    display: grid;
    grid-template-columns: minmax(80px, 120px) minmax(500px, 1fr);
    min-height: 500px;
}

@media(max-width: 768px) {
    .setting_section {
        grid-template-columns: 1fr;
    }
}


.menu_section {
    height: 100%;
    border-right: 1px solid var(--primary-border-color);
    margin-bottom: 7px;
}

.menu_section ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: block;
}

.menu_section li {
    padding: 10px 15px;
    cursor: pointer;
    font-size: 14px;
    margin: 0;
    color: var(--menu-color);
    display: flex;
    align-items: center;
    column-gap: 7px;
}

.menu_section svg {
    font-size: 18px;
}

.menu_section li:last-child {
    border: none;
    margin: 0;
}

.active {
    background-color: var(--menu-active);
    color: var(--menu-active-color) !important;
}

.active svg {
    color: var(--menu-active-color) !important;
}

.setting_body {
    font-size: 16px;
}

.menu_mobile {
    align-items: center;
    column-gap: 3px;
    margin: 10px 0 0 0;
    justify-content: center;
    display: none;
    cursor: pointer;
}

.menu_mobile svg {
    font-size: 25px;
}


@media(max-width: 768px) {
    .menu_mobile {
        display: flex;
    }

    .menuOpen {
        display: block !important;
    }

    .menuClose {
        display: none !important;
    }
}