.grid_template {
    display: flex;
    flex-direction: row;
    height: 100%;
}

.grid_template .grid_element_sidebar {
    height: 100%;
    z-index: 6;
}

.grid_template .grid_element_main {
    height: 100%;
    width: 100%;
    display: inline-table;
}

.grid_template .body_section {
    padding: 0px 25px;
}

@media(max-width: 768px) {
    .grid_template .body_section {
        padding: 0px 10px;
    }
}


.main_section {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}

.head_section {
    text-align: center;
    color: var(--primary-bg-text);
}

.head_section h1 {
    font-size: 35px;
    margin: 0;
    font-weight: 400;
    line-height: 1.2;
}


.head_section p {
    text-align: right;
    margin: 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.2;
}

.head_section p span {
    color: #fa9900;
}

.loader svg {
    stroke: var(--count-loader-stroke);
}

.loader {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
}

.loader svg {
    stroke: var(--count-loader-stroke);
}

.main_section_nav {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80vh;
    width: 100%;
    color: var(--primary-bg-text)
}

.head_section_nav {
    text-align: center;
}

.head_section_nav h1 {
    font-size: 27px;
    font-weight: 400;
    margin: 0;
    line-height: 1.2;
}


.head_section_nav p {
    text-align: right;
    margin: 0;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.2;
}

.head_section_nav p span {
    color: #fa9900;
}