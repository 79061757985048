.profile_section {
    padding: 10px 15px 20px 15px;
}

.heading {
    font-size: 16px;
    font-weight: 400;
}

.grid {
    display: grid;
    grid-template-columns: repeat(2, minmax(200px, 1fr));
    align-items: center;
    column-gap: 10px;
    row-gap: 10px;
}

@media(max-width: 768px) {
    .grid {
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }
}

.body {
    margin-top: 15px;
}

.flex {
    display: flex;
}

.section {
    margin-top: 15px;
}

.section p {
    margin: 0;
    font-weight: 400;
    font-size: 16px;
}


.section hr {
    margin: 5px 0 10px 0;
}

.btn_section {
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loader svg {
    stroke: var(--count-loader-stroke);
}

.loader {
    height: 60vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader svg {
    stroke: var(--count-loader-stroke);
}


.btnLoader svg {
    width: 20px;
    height: 20px;
}

.updateBtn {
    display: block;
    margin-left: auto !important;
    margin-right: auto !important;
    margin-top: 20px !important;
}

.resetBtn {
    padding: 5px 15px;
}