.side_bar_main {
  position: relative;
  min-width: 190px;
  transition: 200ms all ease-in-out;
  z-index: 5;
  height: 100vh;
  /* box-shadow: inset 0px 0px 0px 0 #00000083, inset 0px -4px 3px 0px #ccc, inset 0 0px 0px 0px #fff; */
}

.side_bar {
  position: fixed;
  height: 100%;
  min-width: 190px;
  transition: min-width 200ms ease-in-out;
  background-color: var(--sidebar-bg);
}

@media (max-width: 768px) {
  .side_bar_main {
    position: fixed;
    width: 20px;
  }
}

.menu_bar {
  height: 100%;
  border-right: 1px solid var(--sidebar-border-color-menu);
}

.side_bar_main.collapse {
  position: relative;
}

.side_bar_main.collapse:hover {
  position: relative;
  height: 100%;
  min-width: 40px;
  width: 40px;
}

@media (max-width: 768px) {
  .side_bar_main.collapse:hover {
    position: fixed;
  }
}

.side_bar_main .menu_title svg {
  font-size: 0px;
  transition: all 200ms ease-in-out;
}

.side_bar_main.collapse:hover .menu_title svg {
  font-size: 0px;
}

.side_bar_main.collapse .menu_title svg {
  font-size: 20px;
  transition: all 200ms ease-in-out;
}

.side_bar_main.collapse .bottom_menu {
  position: absolute;
  bottom: 63px;
  transition: padding 200ms ease-in-out;
}

.side_bar_main.collapse .child_list {
  opacity: 0;
  display: none;
}

.side_bar_main.collapse:hover .child_list {
  opacity: 1;
  display: block;
  visibility: visible;
}

/* .side_bar_main.collapse .side_bar {
    padding-bottom: 60px;
} */

.side_bar_main.collapse .click_padd {
  padding: 5px 5px;
}

.side_bar_main.collapse:hover .click_padd {
  padding: 5px 6px;
}

.side_bar_main.collapse:hover .side_bar {
  position: fixed;
  height: 100%;
  min-width: 190px;
  width: 0;
}

.side_bar_main.collapse:hover {
  min-width: 190px;
}

.side_bar_main.collapse:hover .menu_bar_content {
  width: auto;
  padding: 0 8px;
  padding-top: 0;
  max-height: 100%;
  min-height: 100%;
  opacity: 1;
}

.side_bar_main.collapse:hover .menu_text {
  opacity: 1;
}

.side_bar_main.collapse:hover .menu_bar_content p {
  opacity: 1;
}

.side_bar_main.collapse:hover .menu_bar_content hr {
  opacity: 1;
}

.side_bar_main.collapse {
  min-width: 40px;
  width: 40px;
}

.side_bar_main.collapse .side_bar {
  min-width: 40px;
  width: 40px;
}

.menu_bar_content_section {
  position: relative;
  overflow: hidden;
  height: 100%;
  padding-bottom: 137px;
}

.side_bar_main.collapse .menu_bar {
  overflow: hidden;
}

.menu_title_section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px 5px 7px;
}

.menu_title_section p {
  margin: 0;
  font-weight: 400;
  font-size: 13px;
  color: var(--menu-color);
  opacity: 1;
  transition: 200ms all ease;
}

.side_bar_main.collapse .menu_text,
.side_bar_main.collapse .menu_bar_content hr,
.side_bar_main.collapse .menu_bar_content .menu_title {
  transition: opacity 200ms ease-in-out;
  opacity: 0;
}

.side_bar_main.collapse .menu_bar_content {
  padding-left: 0;
}

.side_bar_main.collapse .menu_bar_content .active {
  border-radius: 0;
}

.side_bar_main.collapse:hover .menu_bar_content .active {
  border-radius: 5px;
}

.side_bar_main.collapse .menu_bar_content li svg {
  font-size: 19px;
}

.side_bar_main.collapse:hover .menu_bar_content li svg {
  font-size: 19px;
}

.menu_bar_content {
  padding: 0 8px;
  padding-top: 0;
  overflow: auto;
  max-height: 100%;
  min-height: 100%;
  min-width: 190px;
  opacity: 1;
  transition: 200ms all ease;
}

.menu_bar_content p {
  margin: 0;
  font-weight: 400;
  font-size: 13px;
  color: var(--menu-color);
  opacity: 1;
  transition: 200ms all ease;
}

.menu_bar_content .menu_title {
  position: sticky;
  top: 0;
  padding-bottom: 5px;
  border-bottom: 1px solid var(--sidebar-border-color);
}

.menu_title {
  display: flex;
  align-items: center;
  column-gap: 5px;
}

.menu_bar_content hr {
  margin: 2px 0 8px 0;
  color: rgba(0, 0, 0, 0.1);
  transition: 200ms all ease;
}

.navBar {
  list-style-type: none;
  color: var(--menu-color);
  font-weight: 400;
  height: 100%;
  font-size: 13px;
  padding: 0;
}

.navBar li {
  margin: 3px 0;
  width: 100%;
  cursor: pointer;
}

.bottom_menu .navBar li {
  padding: 4px 5px;
}

.bottom_menu.menu_bar_content {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
}

.click_padd {
  padding: 5px 6px;
}

.click_padd_count {
}

.menu_btn:hover {
  background-color: var(--hover);
}

.link_menu {
  width: 100%;
  margin: 8px 0;
}

.active li {
  background-color: var(--menu-active);
  color: var(--menu-active-color);
  border-radius: 5px;
}

.active li:hover {
  background-color: var(--menu-active);
  color: var(--menu-active-color);
  border-radius: 5px;
}

.active {
  background-color: var(--menu-active);
  color: var(--menu-active-color);
  border-radius: 5px;
}

.active:hover {
  background-color: var(--menu-active);
  color: var(--menu-active-color);
  border-radius: 5px;
}

.navBar a {
  color: var(--menu-color) !important;
  font-weight: 400;
}

.navBar svg {
  margin-right: 8px;
  font-size: 19px;
  margin-left: 5px;
}

.sidebar_menu_name span {
  display: flex;
  align-items: center;
}

.menu_btn span {
  display: flex;
  align-items: center;
}

.menu_btn {
  display: flex;
  align-items: center;
  border-radius: 5px;
}

.menu_btn svg {
  font-size: 19px;
  margin-left: 5px;
}

.has_children {
  padding-bottom: 0 !important;
}

.child_list {
  list-style-type: none;
  padding: 2px 0 0 27px;
  font-size: 13px;
}

.child_list li {
  padding-left: 10px !important;
}

.child_list li a:last-child a {
  padding-bottom: 0;
  margin-bottom: 0;
}

.extend_product {
  width: 100%;
}

.extend_product span {
  display: flex;
  align-items: center;
}

.menu_name {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.menu_name button {
  border-radius: 5px !important;
  padding: 4px;
}

.menu_name button:hover {
  background-color: transparent;
}

.menu_name button::after {
  content: "\25BC";
  font-size: 9px;
  padding: 0px;
  border-radius: 5px;
  color: var(--menu-color);
}

.menu_name button.hide::after {
  content: "\25B2";
  font-size: 9px;
  padding: 0px;
  border-radius: 5px;
}

.menu_accordion {
  position: absolute;
  background-color: var(--sidebar-bg);
  color: var(--menu-color);
  z-index: 12;
  box-shadow: 0 0 2px 0px var(--expand-btn-shadow);
  border-radius: 100%;
  height: 25px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  right: -15px;
  top: 47px;
  cursor: pointer;
  transition: 50ms all ease-in-out;
}

.menu_accordion:hover {
  background-color: var(--primary);
  color: #fff;
}

.menu_accordion::after {
  content: "\0276E";
  font-size: 13px;
}

.side_bar_main.collapse .menu_accordion::after {
  content: "\0276F";
  font-size: 13px;
}

.side_bar_main.collapse .child_active {
  background-color: var(--menu-active);
  color: var(--menu-active-color);
}

.side_bar_main.collapse .child_active p {
  color: var(--menu-active-color);
}

.side_bar_main.collapse:hover .child_active p {
  color: inherit;
}

.side_bar_main.collapse:hover .child_active {
  background-color: inherit;
  color: inherit;
}

.bottom_menu {
  position: absolute;
  bottom: 63px;
  min-height: auto !important;
  box-shadow: var(--bottom-menu-shadow);
  background-color: var(--sidebar-bg);
  transition: all 0s;
}

.bottom_menu .navBar {
  margin: 5px 0 10px 0;
}

.expand_padd {
  padding-bottom: 5px;
}

.count_notification {
  font-size: 10px;
  width: 100%;
  text-align: center;
}

.loader_count {
  display: flex;
  justify-content: center;
  margin-left: 7px;
}

.loader_count svg {
  width: 17px;
  height: 17px;
  stroke: var(--count-loader-stroke);
}

.flex_center {
  display: flex;
  align-items: center;
}

.count::after {
  content: attr(data-count);
  background-color: transparent;
  color: var(--primary-bg-text);
  border-radius: 50%;
  border: 1px solid var(--sidebar-border-color);
  min-width: 19px;
  height: 19px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
  font-size: 12px;
  padding-top: 0;
  position: relative;
}

.count.active::after {
  content: attr(data-count);
  background-color: var(--secondary);
  color: var(--count-menu-color);
  border-radius: 50%;
  border-color: var(--primary-text);
  min-width: 19px;
  height: 19px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
  font-size: 12px;
  padding-top: 0;
  position: relative;
}

.count_active::after {
  content: attr(data-count);
  background-color: var(--secondary);
  color: var(--secondary-text);
  border-radius: 50%;
  min-width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  font-size: 12px;
  padding-top: 0;
  position: relative;
}

.version_section {
  color: rgba(128, 128, 128, 0.541) !important;
  text-align: center;
  font-size: 12px !important;
  padding: 10px 0 3px 0;
}

.logo_section {
  font-size: 20px;
  padding: 15px 20px;
  color: var(--logo-color);
  font-weight: 400;
  display: grid;
  grid-template-columns: 25px auto;
  align-items: center;
  border-bottom: 1px solid var(--sidebar-border-color);
  border-right: 1px solid var(--sidebar-border-color);
  user-select: none;
}

.logo_svg {
  height: 25px;
  width: 25px;
}

.logo_section span {
  position: relative;
  font-weight: 300;
  color: var(--primary-bg-text);
}

.side_bar_main.collapse .logo_section span {
  opacity: 0;
  visibility: hidden;
  transition: visibility 10ms ease-in-out, opacity 200ms ease-in-out;
}

.side_bar_main.collapse:hover .logo_section span {
  opacity: 1;
  visibility: visible;
}

.side_bar_main.collapse .logo_section {
  padding: 15px 7px;
  transition: padding 50ms ease-in-out;
}

.side_bar_main.collapse:hover .logo_section {
  padding: 15px 20px;
}
