.toggle_section {
    border: 1px solid var(--round-border-color);
    width: 62px;
    padding: 2px 3px;
    border-radius: 10px;
    cursor: pointer;
}

.toggle_flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.dark {
    margin-left: 3px;
    z-index: 2;
}

.dark svg {
    font-size: 18.5px;
    color: grey !important;
}

.light {
    margin-right: 2px;
    z-index: 2;
}

.light svg {
    font-size: 19px;
    color: grey;
    transition: color 0.1s ease-in-out;
}

.toggle {
    position: absolute;
    width: 25px;
    height: 25px;
    border-radius: 30%;
    z-index: 1;
    transform: translateX(30px);
    transition: transform 0.2s ease-in-out;
    cursor: pointer;
    background-color: #FC9601;
}

.toggle.left {
    transform: translateX(0px);
    right: auto;
    background-color: var(--teritiary);
}

.light.active svg {
    color: #fff;
}

.light.active svg circle {
    fill: #fff;
}

.dark.active svg {
    color: #fff !important;
    transition: color 0.2s ease-in-out;
}

.dark.active svg>* {
    fill: #fff;
}