.overlay_box {
    display: block;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    background-color: var(--overlay-box);
    min-width: auto;
    min-height: auto;
    border: 1px solid var(--overlay-box-lines);
    border-radius: 10px;
    color: var(--overlay-box-text);
    z-index: 99999;
}

.overlay_blur {
    position: fixed;
    width: 100%;
    height: 100vh;
    backdrop-filter: blur(6px);
    top: 0;
    left: 0;
    background-color: #ffffff15;
    z-index: 12;
}

.overlay_close {
    font-size: 18px;
    cursor: pointer;
    color: var(--overlay-box-close);
}

.close {
    width: 17px;
    height: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    color: var(--overlay-box-close);
    cursor: pointer;
}

.overlay_body {
    width: 100%;
    height: 100%;
    max-height: 500px;
    overflow: auto;
    padding: 10px 15px;
}

.overlay_head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 13px;
    border-bottom: 0.5px solid var(--overlay-box-lines);
}

.overlay_footer {
    display: flow-root;
    padding: 8px 13px;
    border-top: 0.5px solid var(--overlay-box-lines);
}

.overlay_head h6 {
    margin: 0;
    font-weight: 400;
    font-size: 15px;
}

@media(max-width: 768px) {
    .overlay_box {
        min-width: auto;
        min-height: auto;
        width: 87%;
    }
}