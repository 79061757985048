@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200;300;400;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap");

:root[data-theme="light"] {
  --primary: #1a83fe;
  --secondary: #fff;
  --secondary-text: rgb(56, 56, 56);
  --primary-hover: #1a84fede;
  --primary-transparent: #1a84fe54;
  --primary-text: rgb(255, 255, 255);
  --primary-theme-color: #fff;
  --teritiary: #fff;
  --teritiary-text: #000;

  --logo-color: var(--primary);

  --primary-switch-text: var(--primary);

  --primary-border-color: rgba(0, 0, 0, 0.2);
  --primary-box-border-color: rgba(0, 0, 0, 0.1);

  --primary-hr-color: rgba(0, 0, 0, 0.3);

  --primary-bg: #fff;
  --primary-bg-text: #333030;
  --secondary-bg: #fff;

  /* Overlay Box */
  --overlay-box: var(--secondary);
  --overlay-box-text: var(--secondary-text);
  --overlay-box-lines: var(--primary-border-color);
  --overlay-back: #ffffff15;

  /* Table */
  --table-hover: #e0e0e025;

  /* Hover */
  --hover: #1a84fe25;

  /* Button  */
  --primary-button-bg: #1a83fe;
  --primary-button-color: #fff;
  --secondary-button-bg: transparent;
  --secondary-button-color: var(--primary-bg-text);
  --secondary-button-border: rgba(0, 0, 0, 0.2);
  --round-button-color: #000;
  --round-border-color: rgba(0, 0, 0, 0.1);
  --round-button-color-hover: var(--primary);
  --disabled-button-bg: ;

  /* Colors */
  --red: #fd5252;
  --green: #3dd33d;
  --orange: #ff8901;

  /* Header*/
  --header-bg: #fff;
  --header-button-color: #fff;
  --header-border-color: rgba(0, 0, 0, 0.1);
  --sidebar-border-color-menu: rgba(0, 0, 0, 0.1);
  --header-bottom-menu-shadow: -3px 0 5px 0 rgba(100, 100, 100, 0.2),
    inset 0px -4px 3px 0px #222222;
  --header-count-menu-color: #161b22;

  /* Side bar*/
  --sidebar-bg: #fff;
  --menu-active: var(--primary);
  --menu-active-color: #fff;
  --menu-color: #444040;
  --sidebar-border-color: rgba(0, 0, 0, 0.1);
  --bottom-menu-shadow: -3px 0 5px 0 #0003, inset 0px -4px 3px 0px #ccc;
  --count-menu-color: var(--primary-bg-text);
  --expand-btn-shadow: #00000057;

  /* Input */
  --input-color: rgb(33, 37, 41);
  --input-border-color: rgba(0, 0, 0, 0.1);

  /* Loader */
  --count-loader-stroke: var(--primary);

  /* Accordion url */
  --url: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath fillRule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

:root[data-theme="dark"] {
  --primary: #1a84fede;
  --primary-text: white;
  --secondary: #161b22;
  --secondary-text: #fff;
  --primary-hover: #1a83fe;
  --primary-transparent: #1a84fe54;
  --primary-text: rgb(255, 255, 255);
  --primary-theme-color: #fff;
  --teritiary: #21262d;
  --teritiary-text: #fff;

  --primary-switch-text: #fff;

  --primary-border-color: rgba(255, 255, 255, 0.2);
  --primary-box-border-color: transparent;
  --primary-hr-color: rgba(255, 255, 255, 0.3);

  --logo-color: #3894ff;

  /* BG */
  --primary-bg: #0d1117;
  --primary-bg-text: #fff;
  --secondary-bg: #161b22;

  /* Button  */
  --primary-button-bg: var(--primary);
  --primary-button-color: #fff;
  --secondary-button-bg: transparent;
  --secondary-button-color: var(--primary-bg-text);
  --secondary-button-border: rgba(255, 255, 255, 0.1);
  --round-button-color: #000;
  --round-border-color: rgba(255, 255, 255, 0.1);
  --round-button-color-hover: var(--primary);
  --hover: var(--teritiary);

  /* Overlay Box */
  --overlay-box: var(--secondary);
  --overlay-box-text: var(--secondary-text);
  --overlay-box-lines: var(--primary-border-color);
  --overlay-back: #00000015;

  /* Table */
  --table-hover: var(--secondary);

  /* Header*/
  --header-bg: #161b22;
  --header-button-color: #fff;
  --header-border-color: rgba(255, 255, 255, 0.1);
  --header-bottom-menu-shadow: -3px 0 5px 0 rgba(100, 100, 100, 0.2),
    inset 0px -4px 3px 0px #222222;
  --header-count-menu-color: #161b22;

  /* Side bar*/
  --sidebar-bg: #161b22;
  --menu-active: #21262d;
  --menu-active-color: #fff;
  --menu-color: #fff;
  --sidebar-border-color: rgba(255, 255, 255, 0.1);
  --bottom-menu-shadow: -3px 0 5px 0 rgba(100, 100, 100, 0.2),
    inset 0px -4px 3px 0px #222222;
  --count-menu-color: #fff;
  --expand-btn-shadow: #ffffff57;

  /* Input */
  --input-color: rgb(255, 255, 255);
  --input-border-color: rgba(255, 255, 255, 0.2);

  /* Loader */
  --count-loader-stroke: var(--primary-bg-text);

  /* Colors */
  --red: #fd5252;
  --green: #3dd33d;
  --orange: #ff8901;
}

a {
  text-decoration: none;
}

h5 {
  color: var(--primary-bg-text);
  font-weight: 400;
}

html,
body,
#root {
  font-family: "Poppins", sans-serif !important;
  font-weight: 300;
  height: 100%;
  background-color: var(--primary-bg);
}

.heading {
  border-radius: 10px;
  color: var(--primary-bg-text) !important;
}

.primary-btn {
  background-color: var(--primary-button-bg);
  border: none;
  padding: 0px 15px;
  color: var(--primary-button-color);
  border-radius: 3px;
  font-size: 14px;
  transition: all 100ms ease-in-out;
  cursor: pointer;
  margin: 3px;
  line-height: 28px;
  transition: 50ms all ease-in-out;
  flex-shrink: 0;
}

.primary-btn:hover {
  background-color: var(--primary-hover);
}

.primary-btn svg {
  font-size: 15px;
}

.normal-btn {
  background-color: none;
  background: none;
  border: none;
  padding: 3px 15px;
  border-radius: 3px;
  font-size: 15px;
  transition: all 100ms ease-in-out;
  cursor: pointer;
  margin: 3px;
  transition: 50ms all ease-in-out;
}

.refresh-btn {
  background-color: none;
  background: none;
  border: none;
  padding: 3px 10px;
  border-radius: 3px;
  font-size: 20px;
  transition: all 100ms ease-in-out;
  cursor: pointer;
  margin: 3px;
  transition: 50ms all ease-in-out;
  color: var(--primary-bg-text);
}

.refresh_btn_animation svg {
  -webkit-animation: refresh_animation 1s infinite;
  -moz-animation: refresh_animation 1s infinite;
  -ms-animation: refresh_animation 1s infinite;
  -o-animation: refresh_animation 1s infinite;
  animation: refresh_animation 1s infinite;
  color: grey;
}

@keyframes refresh_animation {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.button-margin {
  margin-left: 0;
  margin-right: 3px;
}

.round-btn {
  border: 1px solid var(--round-border-color);
  background-color: transparent;
  color: var(--round-button-color);
  border-radius: 100%;
  min-height: 20%;
  display: flex;
  padding: 7px;
}

.button {
  border: 0;
  background-color: transparent;
  padding: 0 !important;
  margin: 0;
}

.round-btn:hover {
  background-color: var(--hover);
  border-color: var(--hover);
}

.delete-btn {
  background-color: var(--red);
  border: none;
  padding: 3px 15px;
  color: #fff;
  border-radius: 3px;
  font-size: 15px;
  border: 0.5px solid var(--red);
  transition: all 100ms ease-in-out;
  cursor: pointer;
  margin: 3px;
}

.add-btn {
  background-color: var(--green);
  border: none;
  padding: 3px 15px;
  color: #fff;
  border-radius: 3px;
  font-size: 15px;
  border: 0.5px solid var(--green);
  transition: all 100ms ease-in-out;
  cursor: pointer;
  margin: 3px;
}

.secondary-btn {
  background-color: var(--secondary-button-bg);
  padding: 3px 15px;
  color: var(--secondary-button-color);
  border-radius: 3px;
  font-size: 14px;
  border: 0.5px solid var(--secondary-button-border);
  cursor: pointer;
  margin: 3px;
}

.fa {
  font-weight: normal !important;
}

.primary-table {
  width: 100%;
  margin: 5px 0;
  font-size: 14px;
  color: var(--secondary-text);
}

.primary-table .options_flex {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.options_grid {
  display: grid;
}

.primary-table thead {
  border-top: 1px solid var(--primary-border-color);
  border-bottom: 1px solid var(--primary-border-color);
}

.primary-table th {
  font-weight: normal;
  padding: 10px 0;
  color: grey;
  font-size: 13px;
  font-weight: 400;
}

.primary-table th:first-child {
  padding: 0 10px;
}

.primary-table td:first-child {
  padding: 0 10px;
}

.primary-table tr:hover {
  background-color: var(--table-hover);
  cursor: pointer;
}

input:focus,
input:hover {
  outline: 0;
  box-shadow: none;
}

.primary-table td {
  padding: 10px 0;
  font-size: 13px;
}

.primary-table tr {
  border-bottom: 1px solid var(--primary-border-color);
}

.primary-table td.expand {
  padding: 0px 10px !important;
  display: none;
}

.primary-table tr .expand .expand-btn {
  display: none;
  float: right;
}

.primary-table tr .expand .collapse-btn {
  display: none;
  float: right;
}

@media (max-width: 768px) {
  .primary-table {
    border-collapse: separate;
    border-spacing: 0 1em;
  }

  .primary-table thead {
    display: none;
  }

  .primary-table tr {
    box-shadow: 0 0 4px -2px var(--primary-border-color);
    border-radius: 0;
  }

  .primary-table td {
    display: none;
    width: 100%;
    text-align: right;
    padding: 15px 10px !important;
    border-bottom: 1px solid var(--primary-border-color);
  }

  .primary-table td.expand {
    display: inline-block;
    padding: 10px !important;
  }

  .primary-table tr.expanded td {
    display: inline-block;
    padding-bottom: 10px !important;
  }

  .primary-table tr.expanded .expand .expand-btn {
    display: none !important;
  }

  .primary-table tr.expanded .expand .collapse-btn {
    display: block !important;
  }

  .primary-table tr:not(.expanded) .expand .collapse-btn {
    display: none !important;
  }

  .primary-table tr:not(.expanded) .expand .expand-btn {
    display: block !important;
  }

  .primary-table td {
    display: none;
    width: 100%;
    text-align: right;
    padding: 10px 10px;
    border-bottom: 1px solid var(--primary-border-color);
  }

  .primary-table td:nth-child(1) {
    display: block;
  }

  .primary-table td:nth-child(2) {
    display: block;
  }

  .primary-table .options_flex {
    display: block;
    border-bottom: none;
  }

  .primary-table .expand {
    display: block;
    border-bottom: none;
  }

  .primary-table tr {
    border: 1px solid var(--primary-border-color);
  }

  .primary-table td::before {
    content: attr(data-title);
    float: left;
    display: flex;
  }
}

/* Scrollbar */
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.exist_error {
  width: 100%;
  color: rgb(94, 108, 132);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 7vh;
  font-size: 15px;
}

.dropdown-menu {
  min-width: auto !important;
}

.dropdown-menu .dropdown-item:active,
.dropdown-menu .dropdown-item:hover {
  background-color: var(--primary);
  color: #fff;
}

.dropdown button svg {
  color: #6f6f6f;
}

.option-btn {
  padding: 3px 15px;
  border-radius: 3px;
  font-size: 13px;
  background-color: transparent;
  margin: 5px;
  transition: all 50ms ease-in-out;
}

.caribbean-btn {
  border: 1px solid #4cb361;
  color: var(--primary-text);
  background-color: #4cb361;
}

.red-btn {
  border: 1px solid var(--red);
  color: var(--primary-text);
  background-color: var(--red);
}

.blue-btn {
  border: 1px solid var(--orange);
  color: var(--primary-text);
  background-color: var(--orange);
}

.blue-btn {
  border: 1px solid #017cff;
  color: var(--primary-text);
  background-color: #017cff;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* Accordion */

.accordion {
  background: var(--secondary) !important;
  color: var(--secondary-text) !important;
  border-radius: 10px !important;
  margin: 15px 0;
}

.accordion-button {
  outline: 0 !important;
  box-shadow: none !important;
  background: var(--secondary) !important;
  color: var(--secondary-text) !important;
  font-size: 15px;
  border-radius: 10px !important;
}

.remove_accordion_button::after {
  content: none;
}

.accordion-button.collapsed {
  color: var(--primary) !important;
}

.accordion-button:not(.collapsed)::after {
  background: var(--secondary) !important;
  color: var(--secondary-text) !important;
  -webkit-mask-image: var(--url);
  mask-image: var(--url);
}

.accordion-item:first-of-type .accordion-button {
  color: var(--primary-switch-text) !important;
}

.accordion-button::after {
  background: var(--secondary) !important;
  color: var(--secondary-text) !important;
  -webkit-mask-image: var(--url);
  mask-image: var(--url);
}

.accordion-item {
  background: var(--secondary) !important;
  color: var(--secondary-text) !important;
  border: 1px solid var(--primary-box-border-color);
  border-radius: 10px !important;
}

/* Section */

.section_options {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  row-gap: 6px;
}

.section_options button {
  margin-top: 0;
  margin-bottom: 0;
}

.section_flex {
  display: flex;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  row-gap: 10px;
  width: 100%;
  align-items: center;
  margin-bottom: 15px;
}

@media (max-width: 768px) {
  .section_options {
    justify-content: flex-start;
    flex-direction: column;
    align-items: normal;
  }

  .section_flex {
    display: grid;
    grid-template-columns: auto;
    row-gap: 10px;
    width: 100%;
  }
}

.container-fluid {
  padding: 0 35px;
}

/* Change the white to any color */

.hoverSeperator {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 99999999;
}

.capitalize {
  text-transform: capitalize;
}

.loader svg {
  stroke: var(--count-loader-stroke);
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh;
}

.ql-tooltip {
  z-index: 9999 !important;
}

.ql-snow .ql-tooltip::before {
  content: "Enter URL" !important;
}

* {
  scrollbar-width: thin;
  -webkit-tap-highlight-color: transparent;
}

::-webkit-scrollbar {
  width: 8px;
  background: rgb(13, 20, 36);
}

::-webkit-scrollbar-thumb {
  background: #b4b4b4;
  -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
}

::-webkit-scrollbar-corner {
  background: #000;
}

.page_heading {
  font-size: 20px;
  padding: 5px 0 10px 0;
  font-weight: 400;
  color: var(--primary);
}

.long_text_scroll {
  max-width: 100px;
  overflow: auto;
  display: block;
}

.dashboard_box {
  background-color: var(--secondary);
  color: var(--secondary-text);
  border: 1px solid var(--primary-box-border-color);
  border-radius: 20px;
}

/* Tool Tip */

.ectooltip {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.ectooltip:hover.ectooltip::after {
  visibility: visible;
  opacity: 1;
}

.ectooltip::after {
  content: attr(tooltip-text);
  width: max-content;
  max-width: 110px;
  background-color: var(--teritiary);
  border: 1px solid var(--primary-border-color);
  color: var(--teritiary-text);
  text-align: center;
  padding: 3px 3px;
  position: absolute;
  z-index: 1;
  border-radius: 5px;
  font-size: 10px;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s;
  bottom: 100%;
  left: 50%;
  margin-left: -60px;
}

.hidebox::after {
  visibility: none !important;
}

.ectooltip[tooltip-position="left"]::after {
  right: 30px;
  bottom: 0;
  left: auto;
  margin-left: 0px;
}

.ectooltip[tooltip-position="right"]::after {
  left: 30px;
  bottom: 0;
  margin-left: 0px;
}

.ectooltip[tooltip-position="top"]::after {
  bottom: 100%;
  left: 50%;
  margin-left: -60px;
}

.ectooltip[tooltip-position="bottom"]::after {
  top: 100%;
  bottom: auto;
  left: 50%;
  margin-left: -60px;
}

.notification {
  box-shadow: 0px 0px 4px -1px #000;
  opacity: 1;
}

.notification-container {
  overflow: visible;
}

.cursor_pointer {
  cursor: pointer;
}

.disabled-btn,
.disabled-btn:hover {
  background-color: #37424dde;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
