.inputBox {
    position: relative;
    width: 100%;
}

.input_content_section {
    display: flex;
    align-items: center;
    font-size: 14px;
    border: 1px solid var(--input-border-color);
    border-radius: 5px;
    transition: all 100ms ease-in-out;
}


.inputBox label {
    color: var(--primary-bg-text);
    font-size: 14px;
    margin-bottom: 5px;
}

.inputBox label svg {
    margin-right: 5px;
    font-size: 15px;
    position: relative;
    top: -1.5px;
}

.inputBox label sup {
    top: -.4em;
    right: .2em;
    color: var(--red);
}

.inputBox input, .inputBox textarea {
    border: 0;
    font-size: 14px;
    width: 100%;
    padding: 5px 0px 5px 10px;
    color: var(--input-color);
    outline: 0;
    font-weight: 300;
    background-color: transparent;
}

.inputBox input:placeholder-shown, .inputBox textarea:placeholder-shown {
    font-size: inherit;
}

/* .inputBox input:not(:placeholder-shown)+label {}

.inputBox input:not(:placeholder-shown) {} */

.inputBox input:focus+label, .inputBox textarea:focus+label {
    color: var(--primary);
}

.inputBox input:focus, .inputBox textarea:focus {
    border-color: var(--primary);
}


.inputBox .error {
    border: 0.1px solid var(--red);
}

.inputBox .error {
    border: 0.1px solid var(--red);
}

.error label {
    color: var(--red);
}

.error input:not(:placeholder-shown)+label, .error textarea:not(:placeholder-shown)+label {
    color: var(--red);
}

.error input:focus+label, .error textarea:focus+label {
    color: var(--red);
}

.errorText {
    position: relative;
    margin: 0;
    color: var(--red);
    font-weight: 300;
    font-size: 15px !important;
    margin-bottom: 0 !important;
    height: 100%;
}


.errorText svg {
    margin-right: 4px;
}

.message {
    position: relative;
    margin: 0;
    font-weight: 300;
    font-size: 15px !important;
    color: var(--input-color);
    margin-bottom: 0 !important;
    height: 100%;
}

.input_details {
    display: flex;
    align-items: center;
    padding: 5px 7px 5px 7px;
}

@media(max-width: 768px) {
    .message {
        position: relative;
    }
}

.message svg {
    margin-right: 4px;
}

.disable_style svg {
    margin-right: 4px;
    color: grey;
}

.disable_style {
    position: relative;
    margin: 0;
    font-weight: 300;
    height: 100%;
    margin-bottom: 0 !important;
}