.search_select {
    display: block;
    width: 100%;
    position: relative;
    font-size: 14px;
}

.search_btn {
    width: 100%;
    padding: 5px 10px 5px 10px;
    border-radius: 5px;
    position: relative;
}

.error {
    width: 100%;
    border: 1px solid var(--red) !important;
    padding: 4px 10px;
    border-radius: 5px;
    position: relative;
}

.search_select ul {
    position: absolute;
    margin-bottom: 0;
    background-color: var(--secondary);
    border: 1px solid var(--primary-border-color);
    border-radius: 5px;
    padding: 0;
    list-style: none;
    z-index: 10;
    width: 100%;
    max-height: 200px;
    overflow: auto;
}

.search_select li {
    padding: 5px 10px 5px 10px;
}


.search_select li svg {
    margin-right: 5px;
    font-size: 14px;
}

.search_select li.selected {
    background-color: var(--primary);
    color: #fff;
    cursor: pointer;
}

.search_select li:hover {
    background-color: var(--primary);
    color: #fff;
    cursor: pointer;
}

.select_search {
    padding: 7px 10px 5px 10px;
}

.selected_data_section input {
    width: 100%;
    border: none;
    background-color: transparent;
    color: var(--input-color);
    height: 30px;
    font-weight: 300;
}


.overlay {
    position: absolute;
    width: 100%;
    height: 100%;
}

.noData {
    font-size: 14px;
    text-align: center;
    padding: 10px;
    color: grey;
}

.loader svg {
    max-width: 35px;
}

.disabled .search_btn::after {
    content: none !important;
}

.selected_data {
    padding: 5px 10px;
    margin: 3px 3px;
    border-radius: 10px;
    border: 1px solid var(--primary-border-color);
    width: auto;
    display: inline-block;
}

.selected_data p {
    margin: 0;
}

.selected_data_section {
    display: block;
}

.selected_data_item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 2;
    position: relative;
}

.selected_data_item span {
    display: flex;
}


.selected_data_item svg {
    padding: 3px 5px;
    font-size: 11px;
}


.message {
    position: relative;
    height: 5vh;
    display: flex;
    justify-content: center;
    align-items: center;
    color: grey;
}

.message svg {
    margin-right: 4px;
    font-size: 14px;
}

.selected_data_section input {
    border: none !important;
    display: flex;
}